<body class="min-h-screen bg-gray-100">
  <nav class="fixed inset-x-0 max-w-full bg-white">
    <div
      class="mx-auto max-w-full border py-2 text-center lg:py-1 lg:text-center"
    >
      <div class="flex py-1">
        <h1
          class="w-full py-1 text-base font-bold leading-4 tracking-tight text-gray-900 lg:text-lg"
        >
          Mot de passe oublié
        </h1>
      </div>
    </div>
  </nav>
  <div class="mx-2 mb-1 px-4 pt-16">
    <section>
      <div class="mx-auto max-w-screen-lg py-3 lg:py-3">
        <p class="mt-2 max-w-4xl text-sm lg:text-base">
          Entrez l'adresse e-mail associée à votre compte, et nous vous
          enverrons par e-mail un lien pour réinitialiser votre mot de passe.
        </p>
        <form [formGroup]="emailForm" (ngSubmit)="onSubmit()" #form="ngForm">
          <div class="pb-5">
            <input
              type="email"
              placeholder="Adresse email"
              formControlName="email"
              class="mt-3 block h-12 w-full rounded-xl border {{
                email!.invalid &&
                (email!.dirty || email!.touched || form.submitted)
                  ? 'border-red-500'
                  : 'border-gray-200'
              }} text-sm  placeholder-gray-400 transition duration-500 focus:outline-none focus:ring-2 focus:ring-gaia focus:ring-offset-1 md:text-base"
            />
            <div
              *ngIf="
                email!.invalid &&
                (email!.dirty || email!.touched || form.submitted)
              "
              class="text-xs text-red-600"
            >
              <div *ngIf="email!.errors?.['required']">
                Veuillez saisir votre adresse mail.
              </div>
              <div *ngIf="email!.errors?.['email']">
                Veuillez saisir une adresse mail valide.
              </div>
            </div>

            <div class="flex">
              <div class="mx-auto max-w-screen-lg grow">
                <button
                  type="submit"
                  [disabled]="isLoading"
                  class="mt-3 flex w-full justify-center rounded-xl border border-transparent bg-gaia py-3 px-4 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-gaia focus:ring-offset-2"
                >
                  <mat-spinner [diameter]="20" *ngIf="isLoading"></mat-spinner>
                  <div *ngIf="!isLoading">
                    Recevoir l’email de réinitialisation
                  </div>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</body>
