import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatchValidator } from 'src/app/validators/mustMatch.validator';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
  newPasswordForm!: FormGroup;
  code!: string;
  isLoading: boolean = false;
  confirmPasswordShow: boolean = false;
  passwordShow: boolean = false;
  showBackButton: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: { [x: string]: string }) => {
      if (!params['code']) {
        this.router.navigateByUrl('/');
      }
      this.code = params['code'];
    });
    this.newPasswordForm = this.formBuilder.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(100),
            MatchValidator('passwordConfirmation', true),
          ],
        ],
        passwordConfirmation: [
          '',
          [Validators.required, MatchValidator('password')],
        ],
      },
      { updateOn: 'blur' }
    );
  }

  togglePassword() {
    this.passwordShow = !this.passwordShow;
  }
  toggleConfirmPassword() {
    this.confirmPasswordShow = !this.confirmPasswordShow;
  }

  get password() {
    return this.newPasswordForm.get('password');
  }

  get passwordConfirmation() {
    return this.newPasswordForm.get('passwordConfirmation');
  }

  onSubmit() {
    if (this.newPasswordForm.valid) {
      this.isLoading = true;
      this.showBackButton = false;
      this.authService
        .resetPassword(this.newPasswordForm.value, this.code)
        .subscribe({
          error: (e) => {
            this.isLoading = false;
            if (e.graphQLErrors) {
              e.graphQLErrors.forEach((v: any) => {
                if (v.message == 'Incorrect code provided') {
                  v.message = 'Code expiré ou invalide.';
                }
                this.snackbar.open(v.message, '', {
                  panelClass: 'bg-red-600',
                });
              });
            }
          },
          complete: () => {
            this.isLoading = false;

            if (
              this.deviceService.isMobile() ||
              this.deviceService.isTablet()
            ) {
              this.showBackButton = true;
            }
            this.snackbar.open(
              'Votre mot de passe a été modifié avec succés',
              '',
              { duration: 2000, panelClass: 'bg-gaia' }
            );
          },
        });
    }
  }
}
