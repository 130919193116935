import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  environnement: any = environment;
  constructor(private apollo: Apollo) {}

  forgotPassword(formData: any) {
    const { email } = formData;
    return this.apollo.mutate({
      mutation: gql`
        mutation forgotPassword($email: String!) {
          forgotPassword(email: $email) {
            ok
          }
        }
      `,
      variables: {
        email,
      },
    });
  }
  resetPassword(formData: any, code: any) {
    const { password, passwordConfirmation } = formData;
    return this.apollo.mutate({
      mutation: gql`
        mutation resetPassword(
          $password: String!
          $passwordConfirmation: String!
          $code: String!
        ) {
          resetPassword(
            password: $password
            passwordConfirmation: $passwordConfirmation
            code: $code
          ) {
            jwt
          }
        }
      `,
      variables: {
        password,
        passwordConfirmation,
        code,
      },
    });
  }
}
