import { MatSnackBar } from '@angular/material/snack-bar';
import {
  InMemoryCache,
  ApolloLink,
  DefaultOptions,
  createHttpLink,
} from '@apollo/client/core';
import { environment } from '../environments/environment';
import { onError } from '@apollo/client/link/error';
import { Apollo } from 'apollo-angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class GraphQlModule {
  constructor(apollo: Apollo, private snackBar: MatSnackBar) {
    const uri = ` ${environment.apiUrl}/graphql`;

    const errorlink = onError(({ networkError }) => {
      if (networkError)
        this.snackBar.open(
          'Problème de serveur, veuillez réessayer plus tard.',
          '',
          {
            duration: 1000,
            panelClass: 'bg-red-600',
          }
        );
    });

    const httpLink = createHttpLink({ uri });
    const link = ApolloLink.from([errorlink, httpLink]);

    const cache = new InMemoryCache();
    const defaultOptions: DefaultOptions = {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    };
    apollo.create({
      link,
      cache,
      defaultOptions,
    });
  }
}
