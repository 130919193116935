import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorResponse } from '@apollo/client/link/error';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  emailForm!: FormGroup;
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
      },
      { updateOn: 'blur' }
    );
  }

  onSubmit() {
    if (this.emailForm.valid) {
      this.isLoading = true;
      this.authService.forgotPassword(this.emailForm.value).subscribe({
        error: (e: ErrorResponse) => {
          this.isLoading = false;
          if (e.graphQLErrors) {
            e.graphQLErrors.forEach((v: any) => {
              if (v.message == 'This email does not exist') {
                v.message = "Cette adresse mail n'existe pas.";
              }
              this.snackBar.open(v.message, '', {
                panelClass: 'bg-red-600',
              });
            });
          }
        },
        complete: () => {
          this.isLoading = false;
          this.snackBar.open(
            'Si un compte est attribué a cette adresse mail, vous allez recevoir un email de réinitialisation de mot de passe',
            '',
            { duration: 2000, panelClass: 'bg-gaia' }
          );
        },
      });
    }
  }

  get email() {
    return this.emailForm.get('email');
  }
}
